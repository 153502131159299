import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUser } from '../services/userService.js'
import { publicPath } from '../../vue.config'
import { logout } from '../services/userService.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => {
      const user = getUser()
      if(user){
        switch(user.role){
          case 'admin':
            return import( '../components/channels.vue')
          case 'operator':
            return import( '../components/attendance.vue')
          default:
            return import( '../components/mainPanel.vue')
        }
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( '../components/login.vue'),
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import( '../components/mainPanel.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel','channel_manager']
    }
  },
  {
    path: '/attendancesManage',
    name: 'AttendancesManage',
    component: () => import( '../components/AttendancesManage.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import( '../components/contacts.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/reportAttendance',
    name: 'ReportAttendance',
    component: () => import( '../components/reportAttendance.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/reportTabulation',
    name: 'ReportTabulation',
    component: () => import( '../components/reportTabulation.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/reportAverage',
    name: 'ReportAverage',
    component: () => import( '../components/reportAverage.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/reportSurvey',
    name: 'ReportSurvey',
    component: () => import( '../components/reportSurvey.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/reportStats',
    name: 'ReportStats',
    component: () => import( '../components/reportStats.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/reportAccessLog',
    name: 'ReportAccessLog',
    component: () => import( '../components/reportAccessLog.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/reportGupshupTraffic',
    name: 'ReportGupshupTraffic',
    component: () => import( '../components/reportGupshupTraffic.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel', 'admin']
    }
  },
  {
    path: '/reportKanban',
    name: 'ReportKanban',
    component: () => import( '../components/reportKanban.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/reportKanbanPerformance',
    name: 'ReportKanbanPerformance',
    component: () => import( '../components/reportKanbanPerformance.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/reportCampaign',
    name: 'ReportCampaign',
    component: () => import( '../components/reportCampaign.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageDepartments',
    name: 'ManageDepartments',
    component: () => import( '../components/manageDepartments.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageHSMModels',
    name: 'ManageHSMModels',
    component: () => import( '../components/manageHSMModels.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageHSMModels-old',
    name: 'ManageHSMModelsOld',
    component: () => import( '../components/manageHSMModels-old.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/templateHSM',
    name: 'TemplateHSM',
    component: () => import( '../components/templateHSM.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageoperators',
    name: 'Manageoperators',
    component: () => import( '../components/manageoperators.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageSupervisors',
    name: 'ManageSupervisors',
    component: () => import( '../components/manageSupervisors.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/managetags',
    name: 'Managetags',
    component: () => import( '../components/managetags.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/channels',
    alias: '/komforthouse',
    name: 'Channels',
    component: () => import( '../components/channels.vue'),
    meta: {
      requiresAuth: true,
      role: ['admin']
    }
  },
  {
    path: '/manageChannel',
    name: 'ManageChannel',
    component: () => import( '../components/managechannel.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/managemenu',
    name: 'Managemenu',
    component: () => import( '../components/managemenu.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageMenuLinear',
    name: 'ManageMenuLinear',
    component: () => import( '../components/manageMenuLinear.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel', 'channel_manager']
    }
  },
  {
    path: '/manageMenuCustom',
    name: 'ManageMenuCustom',
    component: () => import( '../components/manageMenuCustom.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel', 'channel_manager']
    }
  },
  {
    path: '/manageChatbot',
    name: 'ManageChatbot',
    component: () => import( '../components/manageChatbot.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageList',
    name: 'ManageList',
    component: () => import( '../components/manageList.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageButton',
    name: 'ManageButton',
    component: () => import( '../components/manageButton.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/managemessages',
    name: 'Managemessages',
    component: () => import( '../components/managemessages.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageTabulations',
    name: 'ManageTabulations',
    component: () => import( '../components/manageTabulations.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageCampaigns',
    name: 'ManageCampaigns',
    component: () => import( '../components/manageCampaigns.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageCampaign',
    name: 'ManageCampaign',
    component: () => import( '../components/manageCampaign.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageQuickAnswers',
    name: 'ManageQuickAnswers',
    component: () => import( '../components/manageQuickAnswers.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageModules',
    name: 'ManageModules',
    component: () => import( '../components/manageModules.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageKanbans',
    name: 'ManageKanbans',
    component: () => import( '../components/manageKanbans.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/manageKanban',
    name: 'ManageKanban',
    component: () => import( '../components/manageKanban.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/productsList',
    name: 'ProductsList',
    component: () => import( '../components/productsList.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/attendance',
    name: 'Attendance',
    component: () => import( '../components/attendance.vue'),
    meta: {
      requiresAuth: true,
      role: ['operator']
    }
  },
  {
    path: '/qr',
    name: 'QR',
    component: () => import( '../components/qr.vue'),
    meta: {
      requiresAuth: true,
      role: ['admin']
    }
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import( '../components/groups.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },
  {
    path: '/api',
    name: 'API',
    component: () => import( '../components/api.vue'),
    meta: {
      requiresAuth: true,
      role: ['channel']
    }
  },  
  {
    path: '/log',
    name: 'Log',
    component: () => import( '../components/log.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import( '../components/calendar2.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chat',
    name: 'ChatIframe',
    component: () => import( '../components/chatIframe.vue')
  },
  {
    path: '/messages/:channelId/:attendanceId',
    name: 'MessagesIframe',
    component: () => import( '../components/messagesIframe.vue')
  },
  {
    path: '*',
    name:'404',
    meta: {
      requiresAuth: true,
    }
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: publicPath,
  routes
})

function isAuthenticated() {
  const user = JSON.parse(localStorage.getItem('user'))
  if(user && user._id)
    return true
  else
    return false
}

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const role = to.meta.role
  const name = to.name
  const user = getUser()

  const authenticated = isAuthenticated()
  if (!authenticated && requiresAuth) {
    if(to.path == "/komforthouse") {
      next({ name: 'Login', query: { redirect: to.path } })
    } else {
      next({ name: 'Login' })
    }
  } else {
    if(requiresAuth) {
      const tokenTimestamp = JSON.parse(localStorage.getItem('tokenTimestamp'))
      if(!tokenTimestamp || tokenTimestamp - Date.now() <= -86400000)
        logout()
    }

    const menusPages = [
      'ManageMenu',
      'ManageMenuLinear',
      'ManageMenuCustom',
      'ManageChatbot',
      'ManageList',
      'ManageButton',
    ]

    if(role && 
      ( user?.role === 'channel_manager' ? 
        (
          !(
            user.permissions.includes(name) || 
            (name.includes('Report') && user.permissions.includes('Reports')) || 
            (menusPages.includes(name) && user.permissions.includes('Managemenu')) ||
            (name === 'ManageCampaign' && user.permissions.includes('ManageCampaigns')) ||
            (name === 'TemplateHSM' && user.permissions.includes('ManageHSMModels')) ||
            (name === 'ManageKanban' && user.permissions.includes('ManageKanbans'))
          ) 
          && !role.includes(user.role)
        ) : 
        !role.includes(user.role)
      )
    )
      next({ name: 'Home' })
    else 
      next()
  }
})

export default router