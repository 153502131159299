<template>
    <div>
        <b-alert class="position-fixed" v-model="showAlert" v-bind:variant="msg.success ? 'success' : 'danger'">
            <div class="d-flex">
                <span v-html="formattedText" class="pl-2"></span>
                <b-icon icon="x" @click="showAlert=false" role="button" />
            </div>
        </b-alert>
    </div>
</template>

<script>
export default {
    props:['msg'],
    watch:{
        msg: function(){
            this.showAlert = this.msg.countdown || 5 // time (seconds) the modal will be shown
        }
    },
    computed: {
        formattedText() {
            let text = this.msg.text?.replace(/\n/g, "<br>")

            if (this.msg?.linkTitles && Array.isArray(this.msg.linkTitles)) {
                let linkIndex = 0
                text = text?.replace(/(https?:\/\/[^\s]+)/g, (match) => {
                    if (linkIndex < this.msg.linkTitles.length) {
                        const title = this.msg.linkTitles[linkIndex]
                        linkIndex++
                        return `<a href="${match}" target="_blank">${title}</a>`
                    } else {
                        return match // If more links than titles, keep the original URL
                    }
                })
            } else if (text) {
                text = text?.replace(
                    /(https?:\/\/[^\s]+)/g,
                    '<a href="$1" target="_blank">$1</a>'
                )
            }

            return text
        },
    },
    data() {
        return {
            showAlert: false,
        }
    }
}
</script>

<style>
    .alert .close{
        background-color: transparent;
        border: none;
        font-size: 1.25em;
    }

</style>

<style scoped>
    .alert{
        top: 2em;
        right: 2em;
        z-index: 1059;
    }
</style>