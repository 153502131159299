<template>
    <div class="sidebar vh-100" id="sidebar" @mouseover="pullPopoverToTheLeft">
        <a href="/">
            <div class="d-flex flex-column align-items-center py-3 logoWrapper">
                <!-- <b-img :src="require(`../assets/images/logomarca/icone.png`)"></b-img> -->
                <b-img :src="verifyPhotoURL(user.iconURL)"></b-img>
            </div>
        </a>
        <div v-for="item in icons" :key="item.id">
            <div :id="`popover-submenu${1}${item.text}`" class="icon-row py-3 position-relative" :class="{ active: currentPath == item.url, 'darkenOnHover text-light': item.text == 'QRCODE', 'bg-green2': item.text == 'QRCODE' && (verifyChannelsStatus()), 'bg-danger': item.text == 'QRCODE' && (!verifyChannelsStatus()) }" @click="goTo(item); verifyModal(`modal-${item.text}`)" v-if="item.show">
                <sidebar-submenu :menu="item" :depth="1" v-if="item.submenus?.length" />
                <div class="position-relative w-100 d-flex flex-column align-items-center justify-content-center">
                    <b-icon class="icon" :icon="item.name" v-if="item.name"></b-icon>
                    <i class="bi icon d-flex" :class="item.bootstrapIcon" v-if="item.bootstrapIcon"></i>
                    <div class="icon-text">{{item.text}}</div>
                    <div class="icon-number" v-if="item.number">{{item.number}}</div>
                    <div class="rside" v-if="!fullscreen"></div>
                </div>
            </div>
        </div>
        <b-modal id="modal-QRCODE" ref="modal-QRCODE" title="Gerar QRCODE" size="sm" header-class="p-0 px-3" body-class="text-center" hide-footer v-if="this.user.channel">
            <div class="mb-3" v-if="secondaryChannels.length && [ user.channel, ...secondaryChannels ].some(ch => ch?.status != 'authenticated' && ch.apiType === 'business')">
                <b-form-group label="Selecione o canal:" label-class="text-start text-secondary">
                    <b-form-select class="text-secondary w-100 rounded p-1 border-grey" v-model="selectedChannel">
                        <b-form-select-option :value="channel" v-for="channel in [user.channel, ...secondaryChannels].filter(el => el.status != 'authenticated' && el.apiType === 'business')" :key="channel._id">{{ channel.channelNumber }}</b-form-select-option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div v-if="secondaryChannels.length ? selectedChannel : true">
                <div class="vstack gap-3" v-if="!selectedChannel || selectedChannel?.status === 'authenticated' || selectedChannel?.apiType !== 'business'">
                    <i class="bi bi-qr-code lh-1 text-secondary" style="font-size: 5em;"></i>
                    <!-- <b-button variant="danger" @click="disconnectQr">Desconectar</b-button> -->
                    QR Conectado!
                </div>
                <div class="text-secondary" v-else-if="selectedChannel?.status === 'loading'">
                    <b-spinner></b-spinner>
                </div>
                <div v-else-if="selectedChannel?.status == 'qr'">
                    <qrcode :value="qr" :options="{ width: 200 }" v-if="qr"></qrcode>
                </div>
                <div class="vstack gap-3" v-else>
                    <i class="bi bi-qr-code lh-1 text-secondary" style="font-size: 5em;"></i>
                    <b-button variant="green" class="text-white" @click="getQr">Gerar QRCODE</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { logout } from '../services/userService.js'
import utils from '../utils/utils.js'
import SidebarSubmenu from './sidebarSubmenu.vue'
import api from '../services/apiService.js'

export default {
    components: {
        SidebarSubmenu
    },
    props: [
        'fullscreen', 'user', 'sidebarWidth', 'mediaURL', 'socket'
    ],
    mounted() {
        this.setIcons()
        this.setChannelStatus()
        this.socketOnEvents()
        this.onCloseModal()
        // setTimeout(() => {
        //     this.setIcons()
        // }, 500);
    },
    watch: {
        $route(to) {
            if(to.path.startsWith('/manage')) {
                this.currentPath = '/manage'
            } else if(to.path.startsWith('/report')) {
                this.currentPath = '/report'
            } else {
                this.currentPath = to.path
            }
        },
        user: {
            deep: true,
            handler() {
                this.setIcons()
                this.setChannelStatus()
            }
        },
    },
    data() {
        return {
            currentPath: '',
            icons: [],
            channelStatus: null,
            secondaryChannels: [],
            selectedChannel: this.user?.channel,
        }
    },
    methods: {
        goTo(item) {
            if(item.url && !item.submenus) {
                if(this.$router.history.current.path != item.url) {
                    if(item.url == '/logout') {
                       logout()
                    } else {
                        this.$router.replace({path:item.url})
                        const content = document.querySelector('#content')
                        if (content)
                            content.scrollTo(0,0)
                    }
                }              
            }            
        },
        pullPopoverToTheLeft() {
            const popover = document.querySelector('.popoverSidebarSubmenu')
            const sidebar = document.querySelector('.sidebar')
            if(popover && sidebar && utils.scrollbarVisible(sidebar)) {
                popover.style.marginLeft = "-.7em";
            }
        },
        verifyPhotoURL(url) {
            if(url) {
                if(!url.includes('http'))
                    return `${this.mediaURL}${url}`
                return url
            }
        },
        getQr() {
            const channel = this.selectedChannel
            channel.id = channel._id
            channel.whatsapp_id = channel.channelNumber
            this.socket.emit('get_qr',channel)
            this.selectedChannel.status = "loading"
        },
        // disconnectQr() {

        // },
        setChannelStatus() {
            this.channelStatus = this.user?.channel?.status
        },
        async getSecondaryChannels() {
            const resp = await api.getSecondaryChannels(this.user.channelId || this.user.roleId)
            // console.log('resp secChannels',resp)
            if(resp.statusCode != 200) {
                this.secondaryChannels = []
            } else {
                this.secondaryChannels = resp.channels
            }

            if(!this.secondaryChannels?.length && !this.selectedChannel)
                this.selectedChannel = this.user.channel
        },
        verifyChannelsStatus() {
            if(this.channelStatus === "authenticated" || this.user?.channel?.apiType !== 'business') {
                if((!this.secondaryChannels.length || this.secondaryChannels.filter(c => c.apiType === 'business').every(c => c.status == "authenticated")) && (this.user?.channel?.apiType !== 'business' || (this.user?.channel?.apiType === 'business' && this.user?.channel?.status === "authenticated")))
                    return true
            }
            return false
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.selectedChannel = null
            })
        },
        socketOnEvents() {
            this.socket.on('qr', (data) => {
                console.log("qr", data, this.selectedChannel)
                if(data.id == this.selectedChannel._id) {
                    this.selectedChannel.status = "qr"
                    this.qr = data.qr
                }
            })

            this.socket.on('authenticated', (channelId) => {
                // console.log('authenticated',channelId)
                if(channelId == this.selectedChannel?._id) {
                    this.selectedChannel.status = 'authenticated'
    
                    if(this.user.channel?._id == this.selectedChannel._id)
                        this.channelStatus = 'authenticated'
                }
            })
        },
        verifyModal(modalId) {
            if(this.$refs[modalId]) {
                if(modalId == "modal-QRCODE" && !this.verifyChannelsStatus()) {
                    this.getSecondaryChannels()
                    this.$refs[modalId].show()
                }
            }
        },
        async checkEnableQR() {
            if (this.user?.channelConfig?.enableQRThroughChannel)
                return true

            if (this.secondaryChannels?.length) {
                for (const channel of this.secondaryChannels) {
                    const config = await api.getChannelConfig(channel._id)
                    this.$set(channel,'channelConfig',config.channelConfig)
                }
                return this.secondaryChannels.some(el => el.channelConfig?.enableQRThroughChannel)
            }
        },
        async setIcons() {
            this.icons = [
                {
                    name: 'house',
                    text: 'Painel',
                    number: '',
                    url:'/',
                    show: this.user ? ['channel','channel_manager'].includes(this.user.role) : false
                },
                {
                    bootstrapIcon: 'bi-qr-code-scan',
                    text: 'QRCODE',
                    number: '',
                    show: false
                },
                {
                    name: 'code-slash',
                    text: 'API',
                    url:'/api',
                    show: this.user ? ((this.user.role == 'channel' || this.user.permissions?.includes('API')) && this.user.channelConfig?.modules?.api) : false
                },
                {
                    name: 'chat-text',
                    text: 'Atendimentos',
                    number: '',
                    url:'/attendancesManage',
                    show: this.user ? (this.user.role == 'channel' || this.user.permissions?.includes('AttendancesManage')) : false
                },
                {
                    name: 'folder2-open',
                    text: 'Gerenciar',
                    number: '',
                    url:'/manage',
                    submenus: [
                        {
                            name: '',
                            text: 'Campanhas',
                            url:'/manageCampaigns',
                            show: this.user ? ((this.user.role == 'channel' && this.user.channelConfig?.modules?.broadcastList) || (this.user.permissions?.includes('ManageCampaigns') && this.user.channelConfig?.modules?.broadcastList)) : false
                        },
                        {
                            name: 'house',
                            text: 'Canal',
                            url:'/manageChannel',
                            show: this.user ? (this.user.role == 'channel' || this.user.permissions?.includes('ManageChannel')) : false
                        },
                        {
                            name: 'menu-up',
                            text: 'ChatBot',
                            number: '',
                            url: (this.user?.channel?.apiType == 'cloud' || this.user?.channel?.apiType == 'gupshup' || this.user?.channelConfig?.modules?.linearChatbot) ? '/manageChatbot' : '/manageMenu',
                            show: this.user ? ((this.user.role == 'channel' && !this.user.channelConfig?.operationalChannelEnabled) || this.user.permissions?.includes('Managemenu')) : false
                        },
                        {
                            name: 'house',
                            text: 'Departamentos',
                            url: '/manageDepartments',
                            show: this.user ? (this.user.role == 'channel' || this.user.permissions?.includes('ManageDepartments')) : false
                        },
                        {
                            name: 'house',
                            text: 'Templates HSM',
                            url: '/manageHSMModels',
                            // url: (this.user.channel?.apiType == 'cloud' || this.secondaryChannels?.some(el => el.apiType == 'cloud')) ? '/manageHSMModels-old' : '/manageHSMModels',
                            show: this.user ? ((this.user.channel?.apiType == 'cloud' || this.user.channel?.apiType == 'gupshup' || this.secondaryChannels?.some(el => el.apiType == 'gupshup') || this.secondaryChannels?.some(el => el.apiType == 'cloud')) && (this.user.role == 'channel' || this.user.permissions?.includes('ManageHSMModels'))) : false
                        },
                        {
                            name: 'chat-square-text',
                            text: 'Mensagens',
                            number: '2050',
                            url:'/managemessages',
                            show: this.user ? (this.user.role == 'channel' || this.user.permissions?.includes('Managemessages')) : false
                        },
                        {
                            name: 'house',
                            text: 'Operadores',
                            url:'/manageoperators',
                            show: this.user ? (this.user.role == 'channel' || this.user.permissions?.includes('Manageoperators')) : false
                        },
                        {
                            name: 'chat-square-text',
                            text: 'Supervisores',
                            number: '',
                            url:'/manageSupervisors',
                            show: this.user ? this.user.role == 'channel' : false
                        },
                        {
                            name: 'tag',
                            text: 'Etiquetas',
                            url:'/managetags',
                            show: this.user ? (this.user.role == 'channel' || this.user.permissions?.includes('Managetags')) : false
                        },
                        {
                            name: 'list',
                            text: 'Respostas Rápidas',
                            number: '',
                            url:'/manageQuickAnswers',
                            show: this.user ? ((this.user.role == 'channel' || this.user.permissions?.includes('ManageQuickAnswers')) && this.user.channelConfig?.modules?.quickAnswers) : false
                        },
                        {
                            name: 'list',
                            text: 'Tabulações',
                            number: '',
                            url:'/manageTabulations',
                            show: this.user ? (this.user.role == 'channel' || this.user.permissions?.includes('ManageTabulations')) : false
                        },
                        // {
                        //     name: 'menu-up',
                        //     text: 'Lista Interativa',
                        //     number: '',
                        //     url:'/manageList',
                        //     show: true
                        // },
                        // {
                        //     name: 'menu-up',
                        //     text: 'Botões Interativos',
                        //     number: '',
                        //     url:'/manageButton',
                        //     show: true
                        // },
                        {
                            name: 'list',
                            text: 'Kanban',
                            number: '',
                            url:'/manageKanbans',
                            show: this.user ? ((this.user.role == 'channel' || this.user.permissions?.includes('ManageKanbans')) && (this.user.channelConfig?.modules?.kanban && this.user.channelConfig?.customerPortfolioEnabled)) : false
                        },
                        {
                            name: 'list',
                            text: 'Módulos',
                            number: '',
                            url:'/manageModules',
                            show: this.user ? (this.user.role == 'channel' || this.user.permissions?.includes('ManageModules')) : false
                        },
                    ],
                    show: this.user ? (this.user.role == 'channel' || this.user.permissions?.includes('Manage')) : false,
                },
                {
                    name: 'collection-fill',
                    text: 'Canais',
                    number: '',
                    url:'/channels',
                    show: this.user ? this.user.role == 'admin' : false
                },
                {
                    name: 'server',
                    text: 'QR',
                    number: '',
                    url:'/qr',
                    show: this.user ? (this.user.role == 'admin' && (this.user.login == 'admin' || this.user.admin?.master || this.user.admin?.showQRPage)) : false
                },
                // {
                //     name: 'log',
                //     text: 'Log',
                //     number: '',
                //     url:'/log',
                //     show: this.user ? (this.user.role == 'admin' && (this.user.login == 'admin' || this.user.admin?.master || this.user.admin?.showQRPage)) : false
                // },
                {
                    name: 'person',
                    text: 'Contatos',
                    url:'/contacts',
                    show: this.user ? (this.user.role == 'channel' || this.user.permissions?.includes('Contacts')) : false
                },
                {
                    name: 'people',
                    text: 'Grupos',
                    // number: '2049',
                    url:'/groups',
                    show: this.user ? ((this.user.role == 'channel' || this.user.permissions?.includes('Groups')) && this.user.channelConfig?.enableGroup) : false
                },
                {
                    name: 'clipboard-data',
                    text: 'Relatórios',
                    url: '/report',
                    submenus: [
                        {
                            name: '',
                            text: 'Atendimentos',
                            url: 'reportAttendance',
                            show: this.user?.role == 'channel' || this.user.permissions?.includes('Reports')
                        },
                        {
                            name: '',
                            text: 'TME/TMA',
                            url: 'reportAverage',
                            show: this.user?.role == 'channel' || this.user.permissions?.includes('Reports')
                        },
                        {
                            name: '',
                            text: 'Tabulações',
                            url: 'reportTabulation',
                            show: this.user?.role == 'channel' || this.user.permissions?.includes('Reports')
                        },
                        {
                            name: '',
                            text: 'Estatísticas',
                            url: 'reportStats',
                            show: this.user?.role == 'channel' || this.user.permissions?.includes('Reports')
                        },
                        {
                            name: '',
                            text: 'Logs de Acesso',
                            url: 'reportAccessLog',
                            show: this.user?.role == 'channel' || this.user.permissions?.includes('Reports')
                        },
                        {
                            name: '',
                            text: 'Tráfego',
                            url: 'reportGupshupTraffic',
                            show: (this.user?.role == 'channel' && (this.user.channel?.apiType == 'gupshup' || this.secondaryChannels.some(el => el.apiType == 'gupshup'))) || this.user.permissions?.includes('ReportGupshupTraffic') || this.user.role == 'admin'
                            // show: this.user.role == 'admin'
                        },
                        {
                            name: '',
                            text: 'Kanban',
                            url: 'reportKanban',
                            show: (this.user?.role == 'channel' || this.user.permissions?.includes('Reports')) && this.user.channelConfig?.modules.kanban
                        },
                        {
                            name: '',
                            text: 'Desempenho',
                            url: 'reportKanbanPerformance',
                            show: (this.user?.role == 'channel' || this.user.permissions?.includes('Reports')) && this.user.channelConfig?.modules.kanban
                        },
                        // {
                        //     name: '',
                        //     text: 'Pesquisa de Satisfação',
                        //     url: 'reportSurvey',
                        //     show: true
                        // },
                    ],
                    show: this.user ? (this.user.role == 'channel' || (this.user.role == 'admin') || this.user.permissions?.includes('Reports')) : false,
                },
                {
                    name: 'bag-dash',
                    text: 'Produtos',
                    url: '/products',
                    submenus: [
                        {
                            name: '',
                            text: 'Lista de Produtos',
                            url: 'productsList',
                            show: true
                        }
                    ],
                    show: this.user ? ((this.user.role == 'channel' || this.user.permissions?.includes('Products')) && this.user.channelConfig?.modules?.products) : false,
                },
                // {
                //     name: 'house',
                //     text: 'Módulos',
                //     number: '',
                // },
                {
                    name: 'power',
                    text: 'Sair',
                    number: '',
                    url: '/logout',
                    show: true
                },
            ]

            await this.getSecondaryChannels()
            
            const showQR = await this.checkEnableQR()

            if (showQR) {
                this.icons.find(el => el.text === 'QRCODE').show = true
            }

            const campaignSubmenu = this.icons.find(el => el.text === 'Gerenciar').submenus.find(el => el.text === 'Campanhas')
            const templateSubmenu = this.icons.find(el => el.text === 'Gerenciar').submenus.find(el => el.text === 'Templates HSM')

            if (!campaignSubmenu.show) {
                for (const channel of this.secondaryChannels) {
                    if (!channel.deleted && channel.apiType === 'gupshup' || channel.apiType === 'cloud') {
                        const channelConfigResp = await api.getChannelConfig(channel._id)
                        if (channelConfigResp?.statusCode === 200 && channelConfigResp.channelConfig?.modules?.broadcastList) {
                            campaignSubmenu.show = true
                        }
                    }
                }
            }

            if (this.secondaryChannels?.length && this.secondaryChannels.some(el => el.apiType === 'gupshup' || el.apiType === 'cloud') && !templateSubmenu.show) {
                templateSubmenu.show = true
            }
        }
    }
}
</script>

<style>
    a{
        text-decoration: none !important;
    }
    .popoverSidebarSubmenu .popover-body{
        padding: 0 !important;
    }
</style>

<style scoped>
    .sidebar {
        text-align: center;
        background-color: hsl(244deg 21% 13%);
        width: 100%;
        max-height: 100vh;
        max-height: 100dvh;
        overflow: hidden;
    }
    .sidebar:hover {
        overflow-y: overlay;
    }
    .sidebar:hover .rside {
        opacity: 0;
    }
    body *::-webkit-scrollbar {
        width: 0.5em !important;
    }
    body *::-webkit-scrollbar-track {
        background: transparent !important;
    }
    body *::-webkit-scrollbar-thumb {
        background-color: hsl(244deg 26% 80%);
    }
    .logoWrapper img {
        height: 50px;
    }
    .logoWrapper:hover:not(.active) {
        background-color: #40407a;
    }
    .icon-row{
        color: hsl(250deg 40% 74%);
        position: relative;
        cursor: pointer;
    } 
    .icon-row:hover:not(.active) {
        background-color: #40407a;
    }
    .icon-text {
        margin-bottom: 0;
        font-size: 0.9em;
    }
    .icon {
        /* margin: 0 auto; */
        margin-bottom: 5px;
        font-size: 1.8em;
    }
    .icon-number {
        color: #fff;
        background-color: hsl(232deg 37% 62%);
        border-radius: 8px;
        font-size: 0.75em;
        font-weight: 500;
        display: inline-block;
        padding: 0 10px;
    }
    .active {
        background-color: hsl(245deg 19% 25%);
    }
    .active .icon{
        color: hsl(10deg 78% 63%);
    }
    .active .icon-text {
        color: #fff;
    }
    .rside {
        position: absolute;
        right: -1em;
        height: 30px;
        width: 30px !important;
        background-color: hsl(230deg 30% 96%);
        border-bottom-left-radius: 0.5em;
        transform: rotate(45deg);
        display: flex;
        align-self: flex-end;
        opacity: 0;
        transition: opacity .5s linear;
    }
    .active .rside {
        opacity: 1;
    }
    .sidebarfullscreen .icon-row {
        padding: 0;
    }
</style>